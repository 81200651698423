.mainContainerAbout{
    background: #ffffff;
    padding-bottom: 100px;
    padding-top: 60px;
}

.Heading{
    grid-column: 1/6;
    grid-row: 1;
    text-align: center;
    font-size:25px;
    font-family: "Bona Nova SC", serif;
    font-weight: 400;
    font-style:normal;
    padding-top:40px;
    color:darkslategrey;
}

.text-right{
position: relative;
animation: myright 5s;
animation-timing-function: ease-in-out;
}

@keyframes myright {
    0% {left: 0px;}
    50% {left:-150px;}
    100%{left:0px}
  }

  .text-left{
    position: relative;
    animation: myleft 5s;
    animation-timing-function: ease-in-out;
    }
    
    @keyframes myleft {
        0% {left: 0px;}
        50% {left:150px;}
        100%{left:0px}
      }

.para {
font-family: cursive;
font-size:large;
line-height: 25px;
letter-spacing: normal;
padding:10px;
}

.test1{
    padding:10px;
    grid-column: 1 / 2;
    grid-row: 2/ 2;

}

.test2{
    grid-column: 2 / 2;
    grid-row: 2/ 2;
}

.test3 {
    padding:10px;
    grid-column: 3 / 3;
    grid-row: 2/ 2;
}

.tested{

    text-align: center;
    color:brown;
    
}

.Accordian{
    grid-column: 1/6;
    grid-row: 3;

}

.Img {

height:400px;
width:400px;
border-radius: 50px;

}

.slideshow-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius:30%;
   
  }
  
  .slideshow-image {
    position: absolute;
    width: 100%;
    height: auto;
  }

  /* About CSs */
.about-design{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.about-design .item{
    max-width: calc(100% - 0px);
    /* background-color: rgb(241 230 181 / 50%); */
    background-color:#ffffff;
    border-radius:10px;
    border: 1px solid rgb(0 0 0 / 13%);
    min-height: 200px;
    padding: 20px;
    text-align: center;
}
.about-design .item img{
    max-width: 80px;
    max-height: 80px;
    margin: 0 auto 20px;
}
.about-design .item h1{
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    line-height: 28px;
}
.about-design .item p{
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 28px;
}

  .about-content{
    /* background-color: rgb(241 230 181 / 50%); */
    background-color:#ffffff;
    padding: 20px;
    border-radius:20px;
    border: 2px solid rgb(0 0 0 / 13%);
  }
  .about-content p{
    font-size: 20px;
    font-family: 'Roboto',sans-serif;
    line-height: 28px;
  }
  .about-content .tested{
    text-align: center;
    color:brown;    
    font-size: 20px;
    font-family: 'Roboto',sans-serif;
    font-weight: 600;
    line-height: 28px;
  }
  /* About CSs */
  .custom-page-title{
    letter-spacing: .06rem;
    color: #232323;
    line-height: 1.3;
    text-align: center;
    margin-top: 0;
    font-size: 32px;
  }
  .custom-wrapper {
    width: auto;
    height: 235px;
    padding: 40px 15px 40px 30px;
    background: #F9F9F9;
    margin-bottom: 30px;
    box-shadow: none;
    border-radius: 10px;
    transition: .5s;
    cursor: pointer;
    overflow: hidden;
}
.custom-wrapper img {
    max-width: 40px;
    max-height: 40px;
    margin-bottom: 20px;
    transition: .5s;
}
.custom-wrapper:hover .icon { 
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 20px;
    border: 2px solid #ffffff;
    border-radius: 2px;
    padding: 5px;
}
.custom-wrapper:hover img { 
    max-width: 45px;
    max-height: 45px;
    margin-bottom: 20px;
}

.custom-wrapper:hover {
    background: #ffffff;    
    color: #055d6b;
    padding: 40px 15px 40px 30px;
}
.custom-wrapper:hover h3 {
    color: #055d6b;
}
.custom-wrapper:hover p {
    color: #055d6b;
}
.custom-wrapper:hover .h-icon {
    transform: scaleX(-1); /* Flip horizontally */
    transition: transform 0.3s ease-in-out; /* Optional: smooth transition */
}
.custom-page-title{
    letter-spacing: .06rem;
    color: #232323;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 32px;
    margin-bottom: 50px;
}
.about-info-image{
    max-height: 300px;
    max-width: 100%;
}
.about-info-title{
    font-size: 24px;
    font-family:var(--font-family);
    line-height: 36px;
    font-weight: 500;
    color:var(--primary-color);
}
.para-info{
    font-size: 16px;
    font-family:var(--font-family);
    line-height: 28px;
    color:var(--text-paragraph-color);
}
.mb-8{
    margin-bottom: 50px;
}