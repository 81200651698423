body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */

  /* monospace; */
  font-family: "Roboto", sans-serif;
}

.nav-achour {
  text-decoration: none !important;
  font-weight: 700;
  margin-right: 30px;
}

.text-black {
  color: black;
}

.text-grey {
  color: #767171;
}
.text-white {
  color: #fff;
}

.text-lightgrey {
  color: #888888;
}

.text-red {
  color: red;
}

.custom-profile-menu {
  right: 40px;
  left: auto;
}
.custom-profile-menu li {
  font-size: 16px;
}
.shop-page{
  min-height: calc(100vh - 300px);
  padding: 50px 0px;
  text-align: center;
}
.blog-page{
  min-height: calc(100vh - 300px);
  padding: 50px 0px;
  text-align: center;
}
.nav-achour {
  text-decoration: none !important;
  font-weight: 700;
  margin-right: 30px;
  align-items: center;
  display: flex;
}


.BannerName{
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
  animation: slidein 7s infinite;

}

.BannerNamewel {
  display:inline-block;
    overflow:hidden;
    white-space:nowrap;
  animation: reveal 7s infinite;

}

.BannerName{

  animation: showup 7s infinite;
}
.BannerNamewel:first-of-type {    /* For increasing performance 
  ID/Class should've been used. 
  For a small demo 
  it's okaish for now */
animation: showup 7s infinite;
}


.BannerName:last-of-type {
  width:0px;
  animation: reveal 7s infinite;
}

.BannerName:first-of-type {
  margin-left:-300px;
  animation: slidein 7s infinite;
}

.BannerTab  {

  animation: showup 7s infinite;

}

.BannerSubHeading{
  animation: showup 7s infinite;

}

@keyframes showup {
  0% {opacity:0;}
  20% {opacity:1;}
  80% {opacity:1;}
  100% {opacity:0;}
}

@keyframes slidein {
  0% { margin-left:-300px; }
  20% { margin-left:-300px; }
  35% { margin-left:0px; }
  100% { margin-left:0px; }
}

@keyframes reveal {
  0% {opacity:0;width:0px;}
  20% {opacity:1;width:0px;}
  30% {width:355px;}
  80% {opacity:1;}
  100% {opacity:0;width:355px;}
}
