/* AddtoCart styles */
.add-to-cart-page{
  min-height: calc(100vh - 400px);
  padding: 50px 0px;
}
.add-to-cart-page .title{
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  font-family: "EB Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
}
.addToCart-item-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.cart-table{
  width: 100%;
  table-layout: fixed;
}
.cart-table th{
  padding: 8px 12px;
}
.cart-table td{
  font-weight: 500;
    font-size: 14px;
    color: #666;
}
.input-block{
  min-width:50px
}
.img-block img{
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto;
}
.no-cart{
  height: calc(100vh - 300px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;   
}
.no-cart img{
  max-width: 100px;
  max-height: 100px;
  margin: 16px auto;
}
.no-cart .no-cart-text{
  font-size: 20px;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  line-height: 28px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color:var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-btn-primary{
  background-color:#055d6b !important;
  font-size: 14px !important;
  padding:8px 16px;
  border: none;    
  color: rgb(255, 255, 255);
  border-radius: 5px;
}
.custom-btn-primary:hover{
  color:#ffffff !important;
}
/* AddtoCart styles */

/* Updated styles */

/* Updated styles */