@media (min-width: 768px) {
  .carousel-inner {
    display: flex;
  }
  .carousel-items {
    margin-right: 0;
    flex: 0 0 33.333333%;
    padding:30px;
    display: block;
  }
}

.imageMagnifier{
  width: 300px;
  /* height: 350px; */
  border: 1px solid #d3d3d3;
  background-color: #fff;
}

.filterButton {
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid rgb(56, 41, 37);
  border-radius: 10px;
}

.filterButton.active {
  background-color: rgb(56, 41, 37);
  color: #fff;
}
.discountBadge {
/* position: absolute;
top: 10px;
right: 10px;
background-color: #ff4d4d;
color: #fff;
padding: 5px 10px;
border-radius: 5px;
font-size: 0.9em;
font-weight: bold;
z-index: 10; */

position: absolute;
  top:5%;
  left: 5%;
  background-color: rgb(56, 41, 37); 
  color: #fff;
  padding: 10px;
  border-radius: 0 0 10px 0; 
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  line-height: 1.2em;
  /* transform: rotate(-45deg);  */
  min-width: 60px; 
  min-height: 40px; 
  z-index: 10;
}
.discountBadge::before {
/* content: "";
position: absolute;
top: 0;
left: 0;
width: 0;
height: 0;
border-top: 40px solid #ff4d4d;
border-right: 40px solid transparent;  */
}
.productCard {
position: relative;
/* background-color: #fff; */
/* border: 1px solid #ddd; */
/* border-radius: 10px; */
overflow: hidden;
/* padding: 20px; */
/* box-shadow: 0 2px 5px rgba(0,0,0,0.1); */
text-align: center;
/* max-width: 300px; */
}