.test{

  max-width: 500px;
}
.Verticalline{
border: 2px solid #d3d3d3;
height: 250px;
width: 5px;
max-width: 5px;
margin: 0 30px;
padding: 0;
}

.contact-page{
max-width: 100%;
margin: 0 auto;
/* background: #f9f9f9; */
padding: 0px 1px 60px;
}
.page-title{
text-align: center;
margin-bottom: 30px;
font-size: 28px;
font-family: 'EB Garamond';
font-weight: 400;
text-transform: uppercase;
}
.custom-btn-primary{
background-color:#055d6b !important;
font-size: 14px !important;
text-transform: capitalize !important;
}
body{
font-size: 14px;
}

.custom-input label, 
.custom-inputtag input {
font-size: 16px;
}

/* //social-content */
.social-content{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px 0;
}
.custom-social-icons{
color: #055d6b !important;
border:1px solid #055d6b !important;
padding: 5px 10px;
border-radius: .25rem;
margin: 0 10px;
}
.custom-social-icons:hover{    
color: #ffffff !important;
border:1px solid #055d6b !important;
background-color: #055d6b !important;
}
.social-content .tel{
margin-bottom: 15px;
font-size: 18px;
font-family: 'Roboto', sans-serif;
font-weight: 600;
color: rgb(56, 41, 37);
text-decoration: none;
}
.social-content .tel:hover{
text-decoration: underline;
}
.devider{
margin-bottom: 20px;
text-align: center;
}
.devider .heading{
font-size: 14px;
font-family: 'Roboto', sans-serif;
font-weight: 600;
color: rgb(56, 41, 37);
}
/* //social-content */

.custom-page-title{
  letter-spacing: .06rem;
  color: #232323;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  font-size: 32px;
  margin-bottom: 50px;
}