.circle {
  position: fixed;
  display: none;
  bottom: 20px;
  right: 30px;
  width: 40px;
  height: 40px;
  border-radius:50%;
  font-size: 17px;
  color: #ffffff;
  background:#055d6b;
  /* border: 1px solid #382925; */
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 0px 8px #ffffff;
  cursor: pointer;
  z-index: 9999;
  transition: 0.5s
}