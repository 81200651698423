.upload-image-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.Paginationdiv {
position: relative;
left:80%;
display:flex;
justify-content: space-between;
max-width: fit-content;
}

.pagination .active a{
    
   background-color:  rgb(5, 93, 107) !important; 
   border-color:rgb(5, 93, 107) !important; 

}

.tester {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
}

.test-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Our Products Styles */
/* body{font-size: 14px;background: #f9f9f9;} */
.our-products-page{
    min-height:calc(100vh - 100px);
    padding: 50px 0px;
}
.our-products-page .our-products-page-title {
    text-align: center;
    font-size: 28px;
    font-family: "EB Garamond", serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.all-image-container{
    border-radius: 5px;
    padding: 20px;
    /* background: #f9f9f9; */
}
.image-filter-container{
    padding-bottom: 14px;
    margin-bottom: 10px;
    max-width: calc(100% - 20px);
}
.image-filter-container .form-title{
    font-size: 16px;
    font-family: "EB Garamond", serif;
    font-weight: 400;
    text-transform: capitalize;
    margin-right: 16px;
}
.image-filter-container .custom-form-select{
    width: 200px;
    font-size: 16px;
    font-family: "EB Garamond", serif;
    font-weight: 400;
    text-transform: capitalize;
}
.product-images-container{
    min-height:50vh;
}
.product-item{
    max-width: calc(100% - 20px);
    margin: 0 auto 20px;
    border: 1px solid #d3d3d3;
    border-radius: 5px !important;
    box-shadow: none !important;
}
.product-item:hover{
    border: 1px solid #382925;
}
.product-item img{
    max-width: 100%;
    max-height: 300px;
}
.product-item .product-name{
    text-align: center;
    font-size: 14px;
    font-family: "EB Garamond", serif;
    font-weight: 600;
    text-transform: uppercase;
    margin:16px 0px;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    text-align: center;
    font-size: 18px;}
/* Our Products Styles */

/* Product details Styles */
.product-details-page{
    background:#f6f3f2;
}
.product-details-container{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background: #f9f9f9;
    padding: 60px 60px;
}
.product-details-container .image-container{
    width:300px;
    height: 500px;
    overflow: hidden;
    border-radius: 5px;
}
.product-details-container .image-container .imageMagnifier{
    max-width:300px;
    max-height: 300px;
}
.product-details-container .image-container .imageMagnifier img{
    max-width:280px;
    max-height: 280px;
}
.product-details-container .info-container{
    padding: 0px 20px;
    width: calc(100% - 300px);
}
.product-details-container .info-container .product-description{
    text-align: left;
    font-size: 14px;
    font-family: "EB Garamond", serif;
    font-weight: 400;
    margin:16px 0px;
}
/* Product details Styles */
.choose-alternatives-container{
    padding: 50px 0px;
  }
  .choose-alternatives-container img{
    max-width: 100px;
    max-height: 100px;
  }
  .choose-alternatives-container .owl-item,
  .choose-alternatives-container .owl-item .card{
    max-width:200px;
    max-height: 200px;
}
  .choose-alternatives-container .owl-item .card img{
    max-width: 160px;
    max-height: 160px;
    margin: 20px auto;
}
.home-card-coursel .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    max-height: 270px;
    max-width: 300px;
    margin: 15px auto;
}
.filterButton {
    background-color: #382925;
    color: #ffffff;
    font-size: 14px;
    padding: 6px 18px;
    border-radius: 12px;
    border: none;
}


@media (min-width: 768px) {
    .carousel-inner {
      display: flex;
    }
    .carousel-items {
      margin-right: 0;
      flex: 0 0 33.333333%;
      padding:30px;
      display: block;
    }
  }

  .imageMagnifier{
    width: 300px;
    /* height: 350px; */
    border: 1px solid #d3d3d3;
    background-color: #fff;
  }

  .filterButton {
    padding: 10px 10px;
    margin: 8px 0px 0px 0px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid rgb(56, 41, 37);
    border-radius: 10px;
    line-height: 32px;
    border: 1px solid #eee;
    text-align: center;
    font-weight: 500;
    width: calc(100% - 10px);
    position: relative;
    left: 5px;
}

.filterButton.active {
    background-color:#055d6b;
    color: #fff;
}
.discountBadge {
    position: absolute;
    top: 0%;
    left: 0%;
    background:#055d6b;
    color: #fff;
    padding: 10px;
    border-radius: 0 0 10px 0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2em;
    /* transform: rotate(-45deg); */
    white-space: nowrap;
    min-width: 80px;
    min-height: 40px;
    z-index: 10;
    display: flex;
    flex-direction: column-reverse;


}
.discountBadge::before {
  /* content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 40px solid #ff4d4d;
  border-right: 40px solid transparent;  */
}
.productCard {
  position: relative;
  /* background-color: #fff; */
  /* border: 1px solid #ddd; */
  /* border-radius: 10px; */
  overflow: hidden;
  /* padding: 20px; */
  /* box-shadow: 0 2px 5px rgba(0,0,0,0.1); */
  text-align: center;
  /* max-width: 300px; */
}


/* Custom Card design */
.new_productCard .product-item {
    width: 100%;
    height: 300px;
    border: 1px solid #e4e4e4;
    background: var(--image-bg-color);
    padding: 0px;
    border-radius: .25rem;
  }

.new_productCard{   
    padding: 0 !important;
    position: relative;
}
.new_productCard .product-item:hover{
    border: 1px solid rgba(0,0,0,0.15);
    background:var(--text-color-white);
  }
.new_productCard img{
    max-height: 240px;
    max-width: 100%;
}
.prd-name{
    font-family: "EB Garamond", serif;
    letter-spacing: .06rem;
    color: #232323;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 16px;
    font-weight: 400;
    margin-top:8px;
    margin-bottom:8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 16px;
}
.prd-price{
    letter-spacing: .06rem;
    color:var(--primary-color);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    font-weight: 700;
}
.imageMagnifier img{
    /* width: auto !important;
max-width: 100%;
max-height: 400px;
margin: 0 auto; */
}
.product_title {
    color: #232323;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 26px;
    line-height: 1.1;
    letter-spacing: .5px;
    margin-top: 0;
    display: block;
}
.product_single_vendor {
    font-weight: 500;
    font-size: 14px;
    color: #666;
}
.quantity {
    border: 1px solid #e9e9e9;
    position: relative;
    width:160px;
    display: inline-flex;
    border-radius:30px;
    margin-top: 16px;
}
.quantity_button {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    font-size: 1.8rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #787878;
}
.quantity_input {
    color: currentColor;
    font-size: 1.4rem;
    font-weight: 500;
    opacity: .85;
    text-align: center;
    background-color: transparent;
    border: 0;
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    padding: 0 .5rem;
    height: 4.5rem;
    width: 100%;
    flex-grow: 1;
    -webkit-appearance: none;
    appearance: none;
}
.add-to-wishlist {
    color: #232323;
    font-size: 14px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.add-to-wishlist i{
    color: #777;
}
.form_label {
    padding-left: 0;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 10px;
    color: #666;
}

.product_form_buttons>* {
    width: 45%;
    margin: 0 5px;
}
.product_tab {
    text-align: center;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.tab-info p{
    text-align: left;
    color: #232323;
    font-weight: 600;
    letter-spacing: .2px;
    margin-bottom: 14px;
    font-size: 15px;
}

.custom-tab {
    color: #232323;
    padding: 9px 12px;
    border: 1px solid transparent;
    background: #f5f5f5;
    letter-spacing: 1px;
    text-decoration: none;
    -webkit-transition: all .9s;
    -moz-transition: all .9s;
    -o-transition: all .9s;
    transition: all .9s;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0;
    position: relative;
    z-index: 1;
    display: block;
    line-height: 20px;
}
.custom-primary-btn{
    width: 45%;
    margin: 0 5px;
    padding: 15px 20px;
    background: #055d6b;
    color: #ffffff;
    border-radius: 30px;
    border: none;
    font-size: 14px;
    font-weight: 400;
}
/* Custom Card design */
.sidebar{
    display: flex;
    align-items: self-start;
    margin-top:32px;
}
.sidebar .left_column {
    width: 240px;
    display: block;
    border: 1px solid #e9e9e9;
    margin-bottom: 30px;
    background: #fff;
    position: sticky;
    top:72px;
    padding-bottom: 60px;
}
.sidebar .right_column {
    width:calc(100% - 240px);
    display: flex;
    align-items: flex-start;
    padding: 0px 0px 0px 20px;
}
.sidebar .left_column  .sidebar-title {
    font-size: 16px;
    padding: 15px;
    text-align: left;
    margin-bottom: 0;
    display: block;
    background: #f5f5f5;
    color: #232323;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: 18px;    
}